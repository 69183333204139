@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SketchTitle';
  src: local('SketchTitle'), url(./assets/fonts/title.ttf) format('truetype');
}

@font-face {
  font-family: 'SketchBody';
  src: local('SketchBody'), url(./assets/fonts/body.ttf) format('truetype');
}

body {
  font-size: 18px;
  font-family: SketchBody;
  @apply text-center;
  /* @apply bg-slate-900;
  @apply text-white; */
  /* @apply bg-white dark:bg-black text-center dark:text-white; */
}
body.dark {
  @apply bg-neutral-800 text-white;
}
body.dark img {
  @apply invert;
}

h1, h2 {
  font-family: SketchTitle;
  font-weight: bold;
}

h1 {
  font-size: 3.6em;
  line-height: 1em;
}

h2 {
  font-size: 1.4em;
}

h3 { 
  font-size: 1em;
  font-weight: bold;
}

button, p, a {
  font-weight: bold;
  
  
  /* color: #000; */
}

.container button, a {
  @apply underline;
}

img {
  /* filter: invert(90%); */
  /* @apply dark:invert; */
}

.mode-toggle {
  @apply mt-4 h-20 block flex self-center justify-center cursor-pointer;
  /* @apply absolute top-10 left-10 cursor-pointer h-40 flex; */
}
.mode-toggle img {
  align-self: center;
  @apply h-full;
}

.tree {
  @apply inline-block mt-10;
  max-width: 50%;
}

.flower {
  @apply hidden;
}

@media only screen and (min-width: 840px) {
  body {
    font-size: 24px;
    /* background: url('./assets/tree.png') right bottom no-repeat fixed;
    background-position: 105% 110%;
    background-size: 30%; */
  }
  .mode-toggle {
    @apply absolute top-4 left-4 cursor-pointer h-40 flex;
  }
  .tree {
    max-width: 600px;
    position: absolute;
    bottom: -5%;
    right: -5%;
    width: 30%;
  }

  .flower {
    @apply left-8;
    display: inline;
    position: absolute;
    bottom: 0;
    width: 100px;
  }
}

#root {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}